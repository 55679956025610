<template>
  <div class="kDialog" v-loading="loading">
    <div class="title">
      <div class="left">
        <svg-icon icon-class="upload" class="svg-icon"></svg-icon>
        {{$t('lang.BText73')}}
      </div>
      <div class="close" @click="close()">
        <svg-icon class="close-icon" icon-class="close"></svg-icon>
      </div>
    </div>
    <div class="kDialog-main">
      <cropper-image class="upload" @uploadImg="uploadImg"></cropper-image>
    </div>
    <div class="background">
      <svg-icon class="back-icon" icon-class="uploadBack"></svg-icon>
    </div>
  </div>
</template>

<script>
import global from "../../utils/global";
import CropperImage from "../cropperImage/cropperImage.vue";
export default {
  name: "kDialog",
  props: {},
  components: { CropperImage },
  data() {
    return {
      loading: false,
      data: {},
      uploadAvatar: "",
    };
  },
  created() {
    let that = this;
    this.$session("userInfo").get((value) => {
      that.uploadAvatar = global.baseUrl + this.source.account_api.uploadAvatar;
      that.data.loginCode = value.loginCode;
    });
  },
  methods: {
    close() {
      this.$emit("close");
    },
    upLoad() {
      console.log("111111111");
      this.loading = true;
    },
    uploadImg(file) {
      var formData = new FormData();
      formData.append("loginCode", this.data.loginCode);
      formData.append("file", file);
      // var formData = {
      //   file: file,
      //   loginCode: this.data.loginCode
      // }
      this.loading = true;
      this.$http.post(this.source.account_api.uploadAvatar, formData, {
          // responseType: 'blob'
      }).then(res => {
          this.loading = false;
          if (res.data.result == "false") {
            this.$notify.error({
              title: res.data.message,
              offset: 100,
              duration: 5000,
            });
          } else {
            this.loading = false;
            this.$emit("uploadSuccess");
          }
      })
      return;
      this.$ajax
        .cPost(this.source.account_api.uploadAvatar, formData)
        .then((res) => {
          this.loading = false;
          if (res.data.result == "false") {
            this.$notify.error({
              title: res.data.message,
              offset: 100,
              duration: 5000,
            });
          } else {
            this.loading = false;
            this.$emit("uploadSuccess");
          }
        });
    },
    uploadSuccess() {
      this.loading = false;
      this.$emit("uploadSuccess");
    },
    uploadError() {},
    beforeUpload(file) {
      let that = this;
      const isLt2M = file.size / 1024 / 1024 < 1;
      if (!isLt2M) {
        that.$notify.error({
          title: "上传文件大小不能超过 1MB!",
          offset: 100,
          duration: 2000,
        });
        return false;
      }

      const isSize = new Promise(function (resolve, reject) {
        let width = 200;
        let height = 200;
        let _URL = window.URL || window.webkitURL;
        let img = new Image();
        img.onload = function () {
          let valid = img.width < width && img.height < height;
          valid ? resolve() : reject();
        };
        img.src = _URL.createObjectURL(file);
      }).then(
        () => {
          return file;
        },
        () => {
          that.$notify.error({
            title: "上传的图片大小最大为200*200!",
            offset: 100,
            duration: 2000,
          });
          return Promise.reject();
        }
      );
      return isSize;
    },
  },
};
</script>

<style lang="scss">
@import "../../assets/base.scss";
.kDialog {
  position: relative;
  .kDialog-main {
    .el-upload-dragger {
      width: 996px;
      border: 4px dashed #b3dcf6;
      height: 492px;
      margin-top: 150px;
      position: relative;
      background: #fff;
      z-index: 100;
    }

    .el-upload__text {
      font-size: 30px;
      color: $mainColor;
      top: 120px;
      line-height: 65px;
      position: absolute;
      text-align: center;
      left: 0;
      right: 0;

      .button {
        background-color: $mainColor;
        color: #fff;
        width: 400px;
        height: 64px;
        line-height: 64px;
        margin: 30px auto;
      }
    }

    .upload-back {
      position: absolute;
      background-color: rgba($color: $background, $alpha: 0.25);
      height: 246px;
      width: 496px;
      bottom: 0;
    }

    .el-upload__tip {
      text-align: left;
      margin-left: 140px;
      font-size: 20px;
      color: $mainColor;
      margin-top: 20px;
    }
  }

  .background {
    height: 630px;
    width: 703px;
    position: absolute;
    bottom: -50px;
    right: -125px;
    .back-icon {
      height: 630px;
      width: 703px;
    }
  }
}
</style>