<template>
  <div class="staffEdit" v-loading="loading">
    <div class="userHead">
      <el-upload
        class="upload"
        drag
        multiple
        :auto-upload="true"
        :show-file-list="false"
        :on-change="upLoad"
        :action="uploadAvatar"
        :on-success="uploadSuccess"
      >
        <div class="userHead-icon" v-show="!form.photo">
          <svg-icon icon-class="bigUserHead" class="svg-icon"></svg-icon>
        </div>
        <div class="camera-icon" v-show="!form.photo">
          <svg-icon icon-class="camera" class="svg-icon"></svg-icon>
        </div>
        <el-image class="image" :src="form.photo" v-if="form.photo"></el-image>
      </el-upload>
    </div>
    <div class="user-main">
      <el-form class="form" :model="form" :rules="rules" ref="form">
        <div class="line line1">
          <el-form-item
            class="form-input firstName"
            prop="firstName"
            :label="$t('lang.SText5')"
            :rules="[
              {
                required: true,
                message: $t('lang.SText165'),
              },
              { max: 20, message: $t('lang.SText154') },
            ]"
          >
            <el-input style="width: 240px" v-model="form.firstName"></el-input>
          </el-form-item>
          <el-form-item
            class="form-input lastName"
            :label="$t('lang.SText6')"
            :rules="[
              {
                required: true,
                message: $t('lang.SText166'),
              },
              { max: 20, message: $t('lang.SText154') },
            ]"
            prop="lastName"
          >
            <el-input style="width: 240px" v-model="form.lastName"></el-input>
          </el-form-item>
        </div>
        <el-form-item
          class="line"
          :label="$t('lang.SText7')"
          prop="accountId"
          :class="{ mail: form.emailFlag == 0 }"
        >
          <div
            class="Verify-button"
            @click="Verify"
            v-if="form.emailFlag == 0 && !reviewFlag"
          >
            {{ $t("lang.SText9") }}
          </div>
          <div class="title-text" v-if="form.emailFlag == 0">
            <div class="Account-status">{{ $t("lang.SText8") }}</div>
          </div>
          <div class="form-input">
            <el-input
              :disabled="form.emailFlag != undefined"
              v-model="form.accountId"
            ></el-input>
          </div>
        </el-form-item>
        <div class="line line1">
          <el-form-item
            class="form-input"
            :label="$t('lang.QText52')"
            prop="countryOrRegion"
            :rules="[
              {
                required: true,
                message: $t('lang.SText161'),
              },
            ]"
          >
            <el-select
              v-model="form.countryOrRegion"
              placeholder=""
              filterable
              style="width: 100%"
              @change="clickCountry"
            >
              <el-option
                v-for="item in countryList"
                :key="item.code"
                :label="item.name"
                :value="item.code"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <el-form-item
          class="line Mobile"
          :label="$t('lang.SText10')"
          prop="mobileNumber"
          :rules="[
            {
              required: true,
              message: $t('lang.NText120'),
            },
            { max: 20, message: $t('lang.SText154') },
          ]"
        >
          <div class="form-input">
            <el-select
              class="step1-select"
              v-model="form.mobileCode"
              filterable
              placeholder=""
              remote
              :remote-method="findPhoneList"
              :loading="selectLoading"
            >
              <el-option
                v-for="item in dialCodeList"
                :key="item.iso2"
                :label="item.iso2"
                :value="item.iso2"
              >
                <div class="phoneOption">
                  {{ item.iso2 + " | " + item.cname }}
                </div>
              </el-option>
            </el-select>
            <el-input
              style="width: 200px"
              v-model="form.mobileNumber"
              class="phone-input unspin"
              type="number"
              @keydown.native="limite"
            >
            </el-input>
          </div>
        </el-form-item>
        <el-form-item
          class="line Phone"
          :label="$t('lang.SText12')"
          prop="phoneNumber"
          :rules="[{ max: 20, message: $t('lang.SText154') }]"
          :popper-append-to-body="false"
        >
          <div class="form-input">
            <el-select
              class="step1-select"
              v-model="form.phoneCode"
              filterable
              placeholder=""
              remote
              :remote-method="findPhoneList"
              :loading="selectLoading"
            >
              <el-option
                v-for="item in dialCodeList"
                :key="item.iso2"
                :label="item.iso2"
                :value="item.iso2"
              >
                <div class="phoneOption">
                  {{ item.iso2 + " | " + item.cname }}
                </div>
              </el-option>
            </el-select>
            <el-input
              style="width: 200px"
              class="phone-input unspin"
              v-model="form.phoneNumber"
              type="number"
              @keydown.native="limite"
            >
            </el-input>
          </div>
        </el-form-item>
        <div v-if="!reviewFlag" class="bottom-button" @click="Save">
          {{ $t("lang.SText13") }}
        </div>
      </el-form>
    </div>
    <!-- <el-dialog :visible.sync="upLoadFlag" width="70%">
      <k-dialog @close="upLoadFlag = false"></k-dialog>
    </el-dialog> -->
  </div>
</template>

<script>
import kDialog from "../../../components/kDialog/kDialog.vue";
import global from "../../../utils/global";
import source from "../../../utils/interFConfig";
export default {
  components: { kDialog },
  data() {
    var validateEmail = (rule, value, callback) => {
      if (value.indexOf("de-well") > 0) {
        callback(new Error(this.$t("lang.SText189")));
      } else {
        callback();
      }
    };
    return {
      upLoadFlag: false,
      mobile: "",
      phone: "",
      loading: false,
      uploadAvatar: global.baseUrl + source.account_api.uploadFile,
      rules: {
        accountId: [
          {
            required: true,
            message: this.$t("lang.SText190"),
            trigger: "change",
          },
          { validator: validateEmail, trigger: "blur" },
          { max: 50, message: this.$t("lang.SText191") },
          {
            pattern: /\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/,
            message: this.$t("lang.SText192"),
          },
          // { max: 50, message: "长度最多 50 个字符" },
          // {
          //   pattern: /\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/,
          //   message: "请输入正确的邮箱地址",
          // },
          // {
          //   validator: validateEmail,
          // },
        ],
      },
      editFlag: false,
      countryList: [],
      userInfo: {},
      dialCodeList: {},
    };
  },
  props: {
    form: {
      type: Object,
      default: function () {
        return {};
      },
    },
    reviewFlag: {
      type: Boolean,
      default: false,
    },
  },
  computed: {},
  created() {
    this.$session("countryList").get((value) => {
      this.countryList = value;
    });
    this.$session("userInfo").get((value) => {
      this.userInfo = value;
    });
    this.findPhoneList();
  },
  methods: {
    limite(e) {
      let key = e.key;
      if (key === "e" || key === ".") {
        e.returnValue = false;
        return false;
      }
      return true;
    },
    change(e) {
      console.log(e);
    },
    choosePhone() {
      let arr = this.form.mobileNumber.split(" ");
      if (arr[1]) {
        this.form.mobileNumber = arr[1];
        this.form.mobileCode = arr[0];
      } else {
        this.form.mobileNumber = arr[0];
        this.form.mobileCode = "";
      }
      let arr2 = this.form.phoneNumber.split(" ");
      if (arr2[2]) {
        this.form.phoneNumber = arr2[1];
        this.form.phoneCode = arr2[0];
      } else {
        this.form.phoneNumber = arr2[0];
        this.form.phoneCode = "";
      }
    },
    upLoadImg() {
      this.upLoadFlag = true;
    },
    upLoad() {},
    uploadSuccess(response, file, fileList) {
      if (response.result == "true") {
        this.$set(this.form, "photo", response.data);
      }
    },
    clickCountry(e) {
      this.findPhoneList("", e);
    },
    Verify() {
      this.loading = true;
      this.$ajax
        .cPost(this.source.staff_api.verify, {
          accountId: this.form.accountId,
          id: this.form.id,
        })
        .then((res) => {
          this.loading = false;
          // this.choosePhone();
          if (res.data.result == "false") {
            this.$notify.error({
              title: res.data.message,
              offset: 100,
              duration: 5000,
            });
          } else {
            this.$notify.success({
              title: res.data.message,
              offset: 100,
              duration: 3000,
            });
            this.$emit("saveSuccess");
          }
        });
    },
    Save() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.form.mobileNumber) {
            this.form.mobileNumber =
              (this.form.mobileCode || "") + " " + this.form.mobileNumber;
          }

          if (this.form.phoneNumber) {
            this.form.phoneNumber =
              (this.form.phoneCode || "") + " " + this.form.phoneNumber;
          }
          this.loading = true;
          let url = "";
          if (this.form.id) {
            url = this.source.staff_api.update;
          } else {
            url = this.source.staff_api.add;
          }
          this.form.companyId = this.userInfo.companyid;
          this.$ajax.cPost(url, this.form).then((res) => {
            // this.choosePhone();
            if (res.data.result == "true") {
              this.$notify.success({
                title: res.data.message,
                offset: 100,
                duration: 3000,
              });
              this.$emit("saveSuccess");
            } else {
              this.$notify.error({
                title: res.data.message,
                offset: 100,
                duration: 5000,
              });
            }
            this.loading = false;
          });
        } else {
          return false;
        }
      });
    },
    findPhoneList(e, country) {
      this.selectLoading = true;
      this.$ajax
        .cPost(this.source.address_api.phone, {
          dialCode: e,
          country: country,
        })
        .then((res) => {
          console.log(res);
          this.selectLoading = false;
          if ((res.data.result = "true")) {
            if (country) {
              this.$set(this.form, "mobileCode", res.data.data[0].iso2);
              this.$set(this.form, "phoneCode", res.data.data[0].iso2);
              return;
            }
            this.dialCodeList = res.data.data;
          } else {
            this.$notify.error({
              title: res.data.message,
              offset: 100,
              duration: 3000,
            });
          }
        });
    },
  },
};
</script>

<style lang="scss">
@import "../../../assets/base.scss";
.staffEdit {
  width: 900px;
  display: flex;
  .el-input__inner {
    height: 48px;
    font-size: 14px;
  }
  .step1-select {
    width: 100px;
  }
  .userHead {
    width: 200px;
    height: 200px;
    background-color: #f9f9f9;
    position: relative;
    .el-upload-dragger {
      width: 200px;
      height: 200px;
    }
    .userHead-icon {
      color: #b3b3b3;

      .svg-icon {
        width: 130px;
        height: 146px;
        margin: 27px 0;
        fill: #b3b3b3;
        stroke: #b3b3b3;
      }
    }
    .camera-icon {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgba($color: #e6e6e6, $alpha: 0.5);
      height: 50px;
      .svg-icon {
        width: 24px;
        height: 22px;
        margin: 14px 0;
        color: #fff;
      }
    }
  }
  .user-main {
    margin-left: 65px;

    .form {
      padding: 10px 10px;
      margin-top: 28px;
      width: 500px;
      .line {
        .title {
          font-size: 20px;
          color: $fontColor3;
          text-align: left;
          line-height: 30px;
          padding-left: 10px;
        }
      }

      .line1 {
        display: flex;
        justify-content: space-between;
        .form-input {
          width: 240px;
        }
      }

      .mail {
        .el-form-item__label {
          margin-bottom: -40px;
        }
        .Account-status {
          float: right;
          color: $background3;
          margin-right: 90px;
          font-size: 14px;
        }

        .Verify-button {
          float: right;
          font-size: 14px;
          color: $background2;
          text-decoration: underline;
          cursor: pointer;
        }
      }

      .Mobile,
      .Phone {
        width: 200px;
        .form-input {
          display: flex;
          width: 300px;
        }
        .step1-select {
          font-size: 20px;
        }
      }

      .bottom-button {
        background-color: $mainColor;
        color: #fff;
        font-size: 30px;
        width: 100%;
        height: 64px;
        line-height: 63px;
        margin-top: 40px;
        cursor: pointer;
      }
    }
  }

  .el-dialog {
    margin-top: 0 !important;
    height: 100%;
    margin: 0 auto;
    .el-dialog__header {
      padding: 0;
    }

    .el-dialog__body {
      padding: 0;
      height: 100%;
    }
  }
}
</style>