<template>
  <div class="MyStaff" v-loading="loading">
    <div class="myStaffTitle">{{ $t("lang.SText92") }}</div>
    <div class="search">
      <div class="radio">
        <el-radio-group v-model="queryForm.state" @change="query">
          <el-radio label="0"> {{ $t("lang.SText120") }} </el-radio>
          <el-radio label="1"> {{ $t("lang.SText101") }} </el-radio>
        </el-radio-group>
      </div>
      <dw-button
        @click="newStaff"
        class="right-button"
        type="addbutton"
        isAdd
        >{{ $t("lang.SText121") }}</dw-button
      >
    </div>
    <dwlist
      :url="source.staff_api.listData"
      :query-params="queryForm"
      ref="staffList"
    >
      <el-table-column prop="photo" label="" width="70px">
        <template slot-scope="scope">
          <div class="userHeadIcon" v-show="!scope.row.photo">
            <svg-icon icon-class="userHead"></svg-icon>
          </div>
          <div class="userHeadIcon" v-if="scope.row.photo">
            <el-image :src="scope.row.photo"></el-image>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="accountId" label="ID"> </el-table-column>
      <el-table-column prop="Name" :label="$t('lang.SText52')">
        <template slot-scope="scope">
          {{ scope.row.firstName }} {{ scope.row.lastName }}
        </template>
      </el-table-column>
      <el-table-column fixed="right" width="250px">
        <template slot-scope="scope">
          <div class="buttonList">
            <el-popover placement="bottom" trigger="hover">
              <div class="moreList">
                <div
                  class="list-button-item"
                  @click="editStaff(scope.row)"
                  v-show="queryForm.state == '0'"
                >
                  {{ $t("lang.QText77") }}
                </div>
                <div
                  class="list-button-item"
                  @click="editStaff(scope.row, 1)"
                  v-show="queryForm.state == '1'"
                >
                  {{ $t("lang.NText146") }}
                </div>
                <div
                  class="list-button-item"
                  @click="clickAction(scope.row, 'Reset')"
                  v-if="queryForm.state == '0'"
                >
                  {{ $t("lang.SText122") }}
                </div>
                <div
                  class="list-button-item"
                  @click="clickAction(scope.row, 'Verify')"
                  v-if="scope.row.emailFlag == '0' && queryForm.state == '0'"
                >
                  {{ $t("lang.SText123") }}
                </div>
                <div
                  class="list-button-item"
                  @click="clickAction(scope.row, 'Recovery')"
                  v-if="queryForm.state == '1'"
                >
                  {{ $t("lang.Recover") }}
                </div>
                <div
                  class="list-button-item"
                  @click="clickAction(scope.row, 'Close')"
                  v-show="queryForm.state == '0'"
                >
                  {{ $t("lang.SText124") }}
                </div>
              </div>
              <div
                class="signOut-icon1"
                :class="{ 'signOut-icon': scope.row.signOut }"
                slot="reference"
              >
                ···
              </div>
            </el-popover>
          </div>
        </template>
      </el-table-column>
    </dwlist>
    <el-dialog
      :visible.sync="editFlag"
      width="70%"
      class="createDialog"
      :destroy-on-close="true"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div class="staff-title">
        <div class="left">
          <svg-icon icon-class="userHead" class="svg-icon"></svg-icon>
          {{ dialogTitle }}
        </div>
        <div class="close" @click="closeDialog()">
          <svg-icon class="close-icon" icon-class="close"></svg-icon>
        </div>
      </div>
      <edit-info
        class="editInfo"
        :form="editInfo"
        @saveSuccess="saveSuccess"
        :reviewFlag="reviewFlag"
      ></edit-info>
    </el-dialog>
    <dw-check-box
      :showCheckBox="showCheckBox"
      @Cancel="Cancel"
      @Save="SureCheckBox"
      :primaryButton="$t('lang.yes')"
      :CancelButton="$t('lang.QText79')"
    >
      {{ message }}
    </dw-check-box>
  </div>
</template>

<script>
import DwCheckBox from "../../../components/dwCheckBox/dwCheckBox.vue";
import dwlist from "../../../components/dwList/dwlist.vue";
import test from "../../../test/test";
import editInfo from "./edit.vue";

export default {
  components: {
    dwlist,
    editInfo,
    DwCheckBox,
  },
  data() {
    return {
      staffData: test.staffData,
      radio: null,
      editFlag: false,
      queryForm: { state: "0" },
      editInfo: {},
      message: "",
      showCheckBox: false,
      clickActionUrl: "",
      loading: false,
      userInfo: {},
      dialogTitle: "",
      reviewFlag: false
    };
  },
  created() {
    this.$session("userInfo").get((value) => {
      this.userInfo = value;
      this.queryForm.companyId = this.userInfo.companyid;
    });
  },
  methods: {
    query() {
      this.$refs.staffList.query();
    },
    signOutHover(data, type) {
      if (type) {
        this.$set(data, "signOut", false);
        return;
      }
      this.$set(data, "signOut", true);
    },
    editStaff(data, review) {
      this.editInfo = { ...{}, ...data };
      if (review == "1") {
        this.dialogTitle = this.$t("lang.NText146");
        this.reviewFlag = true;
      } else {
        this.dialogTitle = this.$t("lang.NText147");
        this.reviewFlag = false;
      }

      if (this.editInfo.mobileNumber) {
        let arr = this.editInfo.mobileNumber.split(" ");
        if (arr[1]) {
          this.$set(this.editInfo, "mobileNumber", arr[1]);
          this.$set(this.editInfo, "mobileCode", arr[0]);
        } else {
          this.$set(this.editInfo, "mobileNumber", arr[0]);
          this.$set(this.editInfo, "mobileCode", "");
        }
      }
      if (this.editInfo.phoneNumber) {
        let arr2 = this.editInfo.phoneNumber.split(" ");
        if (arr2[1]) {
          this.$set(this.editInfo, "phoneNumber", arr2[1]);
          this.$set(this.editInfo, "phoneCode", arr2[0]);
        } else {
          this.$set(this.editInfo, "phoneNumber", arr2[0]);
          this.$set(this.editInfo, "phoneCode", "");
        }
      }
      this.editFlag = true;
    },
    closeDialog() {
      this.editFlag = false;
      this.editInfo = {};
    },
    newStaff() {
      this.editFlag = true;
      this.reviewFlag = false;
      this.editInfo = {};
      this.dialogTitle = this.$t("lang.SText193");
    },
    saveSuccess() {
      this.editFlag = false;
      this.query();
    },
    Cancel() {
      this.showCheckBox = false;
    },
    clickAction(data, type) {
      this.clickActionUrl = "";
      if (type == "Reset") {
        this.clickActionUrl = this.source.staff_api.reset;
        this.message =
          this.$t("lang.SText194") +
          data.firstName +
          " " +
          data.lastName +
          this.$t("lang.SText197");
      }
      if (type == "Verify") {
        this.clickActionUrl = this.source.staff_api.verify;
        this.message =
          this.$t("lang.SText195") +
          data.firstName +
          " " +
          data.lastName +
          this.$t("lang.SText198");
      }
      if (type == "Close") {
        this.clickActionUrl = this.source.staff_api.close;
        this.message =
          this.$t("lang.SText196") +
          data.firstName +
          " " +
          data.lastName +
          this.$t("lang.SText198");
      }
      if (type == "Recovery") {
        this.clickActionUrl = this.source.staff_api.recovery;
        this.message =
          this.$t("lang.SText199") +
          data.firstName +
          " " +
          data.lastName +
          this.$t("lang.SText198");
      }
      this.chooseData = data;
      this.showCheckBox = true;
    },
    SureCheckBox() {
      this.loading = true;
      this.showCheckBox = false;
      this.$ajax
        .cPost(this.clickActionUrl, {
          accountId: this.chooseData.accountId,
          id: this.chooseData.id,
          companyId: this.userInfo.companyid,
        })
        .then((res) => {
          if (res.data.result == "true") {
            this.$notify.success({
              title: res.data.message,
              offset: 100,
              duration: 3000,
            });
            this.query();
          } else {
            this.$notify.error({
              title: res.data.message,
              offset: 100,
              duration: 3000,
            });
          }
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss">
@import "../../../assets/base.scss";
.MyStaff {
  width: 900px;
  padding-bottom: 100px;
  min-height: 600px;
  margin-left: 150px;
  .el-dialog {
    margin-top: 0 !important;
    height: 100%;
    margin: 0 auto;
    .el-dialog__header {
      padding: 0;
      display: none;
    }

    .el-dialog__body {
      padding: 0;
      height: 100%;
    }
  }
  .editInfo {
    padding: 30px 120px;
  }
  .buttonList {
    display: flex;
    justify-content: center;
    .icon {
      width: 10px;
      height: 10px;
      margin-left: 5px;
      margin-top: -5px;
      .svg-icon {
        fill: #fff;
        stroke: #fff;
      }
    }
  }
  .myStaffTitle {
    text-align: left;
    font-size: 30px;
    color: $mainColor;
    margin: 10px 0 40px 0;
  }
  .search {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  .radio {
    padding: 20px 0;
  }
  .newStaff {
    font-size: 18px;
    height: 60px;
    width: 180px;
    line-height: 60px;
  }
  .userHeadIcon {
    height: 48px;
    width: 48px;
    color: $fontColor2;
    border: 1px solid $borderColor1;
    border-radius: 24px;
    overflow: hidden;
    .svg-icon {
      height: 30px;
      width: 30px;
      padding-top: 6px;
      padding-left: 8px;
      color: $fontColor2;
      fill: $fontColor2;
      stroke: $fontColor2;
    }
  }
  .RoleRight {
    display: flex;
    justify-content: space-between;
  }
  .signOut-icon1 {
    color: $fontColor2;
    font-size: 40px;
    cursor: pointer;
    text-align: right;
    padding-right: 10px;
    line-height: 30px;
  }
  .signOut-icon {
    color: $mainColor;
    width: 50px;
    height: 30px;
    text-align: right;
    background-color: $background1;
    border-top: 1px solid $borderColor1;
    border-left: 1px solid $borderColor1;
    border-right: 1px solid $borderColor1;
    z-index: 99;
    padding-right: 10px;
  }
  .signOutHover {
    position: absolute;
    right: 10px;
    top: 20px;
    height: 100px;
    width: 300px;
    z-index: 9;
    .list-button {
      display: flex;
      flex-wrap: wrap;
      position: absolute;
      right: 0;
      top: 29px;
      background-color: $background1;
      font-size: 20px;
      text-align: center;
      border: 1px solid $borderColor1;
      line-height: 30px;
      color: $mainColor;
      cursor: pointer;
      padding: 20px;
    }
    .list-button-item {
      padding: 0 5px;
      text-decoration: underline;
    }
  }
  .staff-title {
    background-color: $mainColor;
    height: 150px;
    font-size: 30px;
    display: flex;
    justify-content: space-between;
    color: #fff;

    .left {
      line-height: 150px;
      padding-left: 50px;
    }

    .svg-icon {
      margin-right: 20px;
      fill: #fff;
      stroke: #fff;
    }

    .close {
      width: 50px;
      height: 50px;
      margin: 50px;
      cursor: pointer;
      .close-icon {
        width: 50px;
        height: 50px;
        fill: #fff;
        stroke: #fff;
      }
    }
  }
}
</style>